
import {defineComponent} from 'vue';
import ListLayout from '@/layouts/ListLayout.vue';
import useTaskList from '@/views/task/list/taskList';
import CreateTaskDialog from '@/components/task/CreateTaskDialog.vue';

export default defineComponent({
  name: 'TaskList',
  props: {
    noActions: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    ListLayout,
    CreateTaskDialog,
  },
  setup(props, {emit}) {
    return {
      ...useTaskList(),
    };
  },
});
